import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import AuthenticationApi from '@youship/api/authentication';

import {
  EMAIL_PATTERN,
  NUMBERS_ONLY_PATTERN,
  PHONE_MAXIMUM_LENGTH,
  PHONE_MINIMUM_LENGTH
} from '@youship/utils/form-validation';

import { FormattedMessage, useIntl } from 'react-intl';

import ReCAPTCHA from 'react-google-recaptcha';

import Input from '@youship/components/objects/input';
import Button from '@youship/components/objects/button';
import Loader from '@youship/components/objects/loader';

import SuccessIllustration from 'images/contact/form_success_illustration.inline.svg';

import './styles.scss';

const MESSAGE_MINIMUM_LENGTH = 10;
const SUBJECT_MINIMUM_LENGTH = 3;

const ContactForm = ({ classNames }) => {
  const { control, errors, handleSubmit, register, reset, trigger, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    }
  });

  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [failed, setFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const errorMessage = 'Sorry, we were unable to process your request at this time. Please try again later.';

  const recaptchaRef = useRef(null);

  const getInputMessage = (name) => {
    if (errors[name]) {
      return errors[name].message;
    }

    return '';
  };

  const getInputStatus = (name) => {
    if (errors[name]) {
      return 'error';
    }

    return 'default';
  };

  const handleFormSubmit = (data) => {
    if (!recaptchaToken) return;

    setIsSubmitting(true);
    submitFormData(data, recaptchaToken);
  };

  const handleInputChange = (value, props) => {
    const { name } = props;

    props.onChange(value);

    if (errors[name]) {
      trigger(name);
    }
  };

  const isFormValid = () => {
    if (!recaptchaToken) return false;

    const inputs = ['name', 'email', 'phone', 'subject', 'message'];

    return inputs.every(input => !!watch(input) && !errors[input]);
  };

  const isButtonDisabled = !isFormValid();

  const submitFormData = (data, googleRecaptchaHash) => {
    AuthenticationApi.requestAContact({
      name: data.name,
      email: data.email,
      phone: data.phone,
      subject: data.subject,
      message: data.message,
      'g-recaptcha-response': googleRecaptchaHash
    })
      .then((response) => {
        if (response?.error) throw new Error(response.error.message || 'Something went wrong while submitting your message.');

        return response;
      })
      .then((response) => {
        reset();
        setFailed(false);
        setIsSubmitting(false);
        setSuccessMessage(response.message);
        setSucceeded(true);

        return response;
      })
      .catch((error) => {
        setFailed(true);
        setIsSubmitting(false);
        setSucceeded(false);
        // NOTE: Add proper erro handling
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    register({ name: 'name' }, {
      required: intl.formatMessage({ id: 'contacts.form.name.required' })
    });

    register({ name: 'email' }, {
      pattern: {
        value: EMAIL_PATTERN,
        message: intl.formatMessage({ id: 'contacts.form.email.pattern' })
      },
      required: intl.formatMessage({ id: 'contacts.form.email.required' })
    });

    register({ name: 'phone' }, {
      pattern: {
        value: NUMBERS_ONLY_PATTERN,
        message: intl.formatMessage({ id: 'contacts.form.phone.pattern' })
      },
      minLength: {
        value: PHONE_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'contacts.form.phone.minlength' },
          { PHONE_MINIMUM_LENGTH }
        )
      },
      maxLength: {
        value: PHONE_MAXIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'contacts.form.phone.maxlength' },
          { PHONE_MAXIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'contacts.form.phone.required' })

    });

    register({ name: 'subject' }, {
      minLength: {
        value: SUBJECT_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'contacts.form.subject.minlength' },
          { SUBJECT_MINIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'contacts.form.subject.required' })
    });

    register({ name: 'message' }, {
      minLength: {
        value: MESSAGE_MINIMUM_LENGTH,
        message: intl.formatMessage(
          { id: 'contacts.form.message.minlength' },
          { MESSAGE_MINIMUM_LENGTH }
        )
      },
      required: intl.formatMessage({ id: 'contacts.form.message.required' })
    });
  });

  const intl = useIntl();

  return (
    <form
      className={`contact-form${classNames ? ` ${classNames}` : ''}`}
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <div className={`contact-form__content${succeeded ? ' contact-form__content--hide' : ''}`}>
        {isSubmitting && (
          <div className="contact-form__loader-wrapper">
            <Loader />
          </div>
        )}
        <div className="contact-form__header">
          <div className="contact-form__title">
            <FormattedMessage id="contacts.form.title" />
          </div>
        </div>
        <div className="contact-form__description">
          <FormattedMessage id="contacts.form.subtitle" />
        </div>
        <div className="contact-form__input-wrapper">
          <Controller
            control={control}
            name="name"
            render={props => (
              <Input
                block
                label={intl.formatMessage({ id: 'contacts.form.name.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'contacts.form.name.placeholder' })}
                status={getInputStatus(props.name)}
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
              />
            )}
          />
        </div>
        <div className="contact-form__input-wrapper">
          <Controller
            control={control}
            name="email"
            render={props => (
              <Input
                block
                label={intl.formatMessage({ id: 'contacts.form.email.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'contacts.form.email.placeholder' })}
                status={getInputStatus(props.name)}
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
              />
            )}
          />
        </div>
        <div className="contact-form__input-wrapper">
          <Controller
            control={control}
            name="phone"
            render={props => (
              <Input
                block
                countryCallingCode="351"
                label={intl.formatMessage({ id: 'contacts.form.phone.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'contacts.form.phone.placeholder' })}
                status={getInputStatus(props.name)}
                type="phone"
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
              />
            )}
          />
        </div>
        <div className="contact-form__input-wrapper">
          <Controller
            control={control}
            name="subject"
            render={props => (
              <Input
                block
                label={intl.formatMessage({ id: 'contacts.form.subject.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'contacts.form.subject.placeholder' })}
                status={getInputStatus(props.name)}
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
              />
            )}
          />
        </div>
        <div className="contact-form__input-wrapper">
          <Controller
            control={control}
            name="message"
            render={props => (
              <Input
                block
                label={intl.formatMessage({ id: 'contacts.form.message.label' })}
                message={getInputMessage(props.name)}
                placeholder={intl.formatMessage({ id: 'contacts.form.message.placeholder' })}
                status={getInputStatus(props.name)}
                type="textarea"
                onBlur={props.onBlur}
                onChange={e => handleInputChange(e.target.value, props)}
              />
            )}
          />
        </div>
        <div className="contact-form__input-wrapper">
          <div className="contact-form__recaptcha">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lds1mEaAAAAAFpoAcJzajALq0gRq2Sf2npMEqEP"
              size="normal"
              onChange={setRecaptchaToken}
            />
          </div>
        </div>
        <div className="contact-form__input-wrapper">
          <Button
            block
            context="primary"
            disabled={isButtonDisabled}
            smallVerticalPadding
            text={intl.formatMessage({ id: 'contacts.form.continue' })}
            type="submit"
          />
          {failed && (
            <div className="contact-form__error">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
      {succeeded && (
        <div className="contact-form__success">
          <SuccessIllustration
            alt="Form success"
            className="contact-form__success-image"
          />
          <div className="contact-form__success-text">
            <div className="h1 contact-form__success-title">
              <FormattedMessage id="contacts.form.success_title" />
            </div>
            {successMessage && (
              <div className="contact-form__success-description">
                {successMessage}
              </div>
            )}
          </div>
        </div>
      )}
    </form>
  );
};

ContactForm.propTypes = {
  classNames: PropTypes.string
};

ContactForm.defaultProps = {
  classNames: null
};

export default ContactForm;
