import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ContactForm from 'components/contact-form';

import MailIcon from 'images/icons/mail.inline.svg';
import PhoneIcon from 'images/icons/phone.inline.svg';
import paperPlaneImage from 'images/contact/PaperPlan.png';

import './styles.scss';

const Contact = () =>{
  const intl = useIntl();
  return (
  <div className="contact">
    <div className="container">
      <div className="row">
        <div className="col col-12 col-md-5">
          <h1 className="contact__title">
            <FormattedMessage id="contacts.header.title" />
          </h1>
          <p className="contact__text">
            <FormattedMessage id="contacts.header.subtitle" />
          </p>
          <div className="contact__info-container">
            <div className="contact__info">
              <p className="contact__info-title">
                <FormattedMessage id="contacts.header.email.label" />
              </p>
              <span
                className="contact__info-text"
              >
                <MailIcon className="contact__info-icon" />
                  <FormattedMessage id="contacts.header.email.text1" />
                  @
                  <FormattedMessage id="contacts.header.email.text2" />
              </span>
            </div>
            <div className="contact__info">
              <p className="contact__info-title">
                <FormattedMessage id="contacts.header.phone.label" />
              </p>
              <a
                href={`tel: ${intl.formatMessage({ id: 'contacts.header.phone.text' })}`}
                className="contact__info-text"
              >
                <PhoneIcon className="contact__info-icon" />
                  <FormattedMessage id="contacts.header.phone.text" />
              </a>
            </div>
          </div>
          <img
            src={paperPlaneImage}
            alt="Paper Planes"
            className="contact__image"
          />
        </div>
        <div className="col col-12 col-md-6 offset-md-1 col-lg-5 offset-lg-2">
          <div className="contact__form" >
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Contact;
