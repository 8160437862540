import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import Contact from 'components/sections/contact';
import Layout from 'components/layout';
import SEO from 'components/seo';

// Hidden for now:
// import MailIcon from 'images/icons/mail.inline.svg';
// import PhoneIcon from '@youship/assets/images/icons/phone.svg';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const bannerImage = 'create-account.png';

const bannerButtons = [
  {
    external: true,
    linkProps: {
      to: `${WEB_APP_URL}/register`
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'partners.banner.buttons.sign_up'
  },
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'partners.banner.buttons.contacts'
  }
];

// Hidden for now:
// const contactInfos = [
//   {
//     title: 'Send Email',
//     content: 'info@you-ship.com',
//     icon: MailIcon
//   },
//   {
//     title: 'Call Us',
//     content: '+351 239 700 326',
//     icon: PhoneIcon
//   }
// ];
// <Contactinfos={contactInfos} />

const ContactUsPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="contacts.title" />
    <Contact />
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      text="contacts.banner.text"
      title="contacts.banner.title"
    />
  </Layout>
);

export default ContactUsPage;
